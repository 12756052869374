<nav class="navbar navbar-expand-lg brand-header">
    <div class="navbar-brand abs">
      <a [href]="vendorWebsite" target="_blank">
        <img [src]="logoImage" [alt]="vendorName" *ngIf="logoImage" />
      </a>
      <span class="ml-2">
        {{companyName}}
      </span>
    </div>
    <button (click)="isNavCollapsed = !isNavCollapsed" class="btn btn-primary navbar-toggler" type="button">
      <i class="fa fa-bars"></i>
    </button>
    <div [ngbCollapse]="!isNavCollapsed" class="collapse navbar-collapse">
      <ul class="navbar-nav">
        <li *ngFor="let navItem of navItems" class="nav-item active">
          <a class="nav-link" (click)="isNavCollapsed = !isNavCollapsed" [routerLink]="navItem.link">{{navItem.caption}}</a>
        </li>
      </ul>
      <ul class="navbar-nav ml-auto">
        <li><a *ngIf="showSwitchLink" class="nav-link btn btn-primary" [routerLink]="switchLink.link">{{switchLink.caption}}</a></li>
        <li><a *ngIf="showAccountLink" class="nav-link" (click)="isNavCollapsed = !isNavCollapsed" [routerLink]="accountLink">{{accountButtonText}}</a></li>
        <li><a class="nav-link" [href]="authenticationLink">{{authenticationButtonText}}</a></li>
      </ul>
    </div>
</nav>